import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../context";
import React from "react";

const Blog = () => {
  const { initialBlogs } = useGlobalContext();

  const navigate = useNavigate();
  
  const handleClick = (id) => {
    navigate(`/news/${id}`)
    console.log(id)
  }

  return (
    <BlogContainer>
      <h2>News</h2>
      <p>Read more about the company news and updates here.</p>

      <div className="blog__section">
        {initialBlogs.map((blog, index) => (
          <React.Fragment key={index}>
            <div className="blog">
              <div className="flexbox" onClick={() => handleClick(blog.id)}>
                <img src={blog.imgUrl} alt="blog-image" />
                <div className="details">
                  <h3 dangerouslySetInnerHTML={{ __html: blog.title }}></h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: blog.description.slice(0, 120) + "... <span>Read more.</span>",
                    }}
                  ></p>
                </div>
              </div>
              {index < initialBlogs.length - 1 && (
                <div className="mid__line"></div>
              )}
            </div>
            {index < initialBlogs.length - 1 && (
              <div className="border__line"></div>
            )}
          </React.Fragment>
        ))}
        {/* <div className="blog">
          <div className="flexbox" onClick={() => handleClick(initialBlogs[0].id)}>
            <img
              src={initialBlogs[0].imgUrl}
              alt="mask"
            />
            <div className="details">
              <h3
                dangerouslySetInnerHTML={{ __html: initialBlogs[0].title }}
              ></h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: initialBlogs[0].description.slice(0, 120) + "... <span>Read more.</span>",
                }}
              >
              </p>
            </div>
          </div>
          <div className="mid__line"></div>
          <div className="flexbox" onClick={() => handleClick(initialBlogs[1].id)}>
            <img src={initialBlogs[1].imgUrl} />
            <div className="details" >
              <h3
                dangerouslySetInnerHTML={{ __html: initialBlogs[1].title }}
              ></h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: initialBlogs[1].description.slice(0, 120) + "... <span>Read more.</span>",
                }}
              ></p>
            </div>
          </div>
        </div> 
        <div className="border__line"></div>
        <div className="blog">
          <div className="flexbox" onClick={() => handleClick(initialBlogs[2].id)} >
            <img src={initialBlogs[2].imgUrl} />
            <div className="details">
              <h3
                dangerouslySetInnerHTML={{ __html: initialBlogs[2].title }}
              ></h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: initialBlogs[2].description.slice(0, 120) + "... <span>Read more.</span>",
                }}
              ></p>
            </div>
          </div>
          <div className="mid__line"></div>
          <div className="flexbox" onClick={() => handleClick(initialBlogs[3].id)}>
            <img src={initialBlogs[3].imgUrl} />
            <div className="details">
              <h3
                dangerouslySetInnerHTML={{ __html: initialBlogs[3].title }}
              ></h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: initialBlogs[3].description.slice(0, 120) + "... <span>Read more.</span>",
                }}
              ></p>
            </div>
          </div>
        </div>  */}
      </div>
    </BlogContainer>
  );
};

const BlogContainer = styled.section`
  padding: 80px 24px;
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: var(--mainPurple);
    text-align: center;
    margin-bottom: 5px;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--fontColor);
    text-align: center;
  }
  .blog__section {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    gap: 24px;
    justify-content: center;
    align-items: center;

    .blog {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 20px;

      .flexbox {
        width: 100%;
        :hover{
          cursor: pointer;
        }
        img {
          width: 100%;
          // height: 131px;
          object-fit: cover;
        }
        h3 {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--mainPurple);
        }
        p {
          display: none;
        }
      }
    }

    .border__line {
      width: 70vw;
      border-bottom: 1px solid #e6e6e6;
    }
  }

  @media (min-width: 768px) {
    h2 {
      margin-bottom: 10px;
    }

    .blog__section {
      margin-top: 80px;
      flex-direction: row;
      gap: unset;
      justify-content: space-evenly;

      .border__line {
        width: 3px;
        height: 300px;
        border-bottom: none;
        border-left: 1px solid #e6e6e6;
      }
    }

    .blog {
      width: 483px;
      flex-direction: column;
      justify-content: center;
      align-items: center !important;

      .mid__line {
        width: 40vw;
        border-bottom: 1px solid #e6e6e6;
        margin: 15px 0;
      }

      .flexbox {
        width: 80% !important;
        display: flex;
        justify-content: center;
        gap: 20px;
        align-items: center;

        img {
          width: 160px !important;
          height: 120px;
        }

        h3 {
          /* font-weight: 600 !important; */
          font-size: 16px !important;
          /* line-height: 28px !important; */
        }
      }
    }
  }

  @media (min-width: 1000px) {
    h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
    }
    .blog {
      width: 483px;
      flex-direction: column;
      justify-content: center;

      .flexbox {
        width: 80% !important;
        display: flex;
        justify-content: center;
        gap: 20px;
        align-items: center;

        img {
          width: 160px !important;
          height: 120px;
        }

        h3 {
          font-weight: 600 !important;
          font-size: 20px !important;
          line-height: 28px !important;
          margin-bottom: 5px;
        }

        p {
          display: block !important;
          text-align: left;
          font-weight: 500 !important;
          font-size: 14px !important;
          line-height: 20px !important;
          color: var(--fontColor) !important;
        }
        /* .block {
          display: flex;
        } */
        Span {
          /* justify-self: flex-end; */
          /* display: block !important; */
          /* text-align: left; */
          font-weight: 500 !important;
          font-size: 14px !important;
          line-height: 20px !important;
          color: var(--mainOrange) !important;
        }
      }
    }
  }
`;

export default Blog;
