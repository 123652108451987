import { useEffect, useState } from "react";
import styled from "styled-components";
import Spinner from "../../components/Spinner";
import { useParams } from "react-router-dom";
import Newsletter from "../Contact/Newsletter";
import Featured from "./Featured";
import moment from "moment";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { useGlobalContext } from "../../context";

const Post = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const { initialBlogs } = useGlobalContext();

  const { id } = useParams();

  useEffect(() => {
    id && getBlogData()
  }, [loading,id]);

  const getBlogData = async ()=> {
    const docRef = doc(db, "blogs", id)
    const blogDetail = await getDoc(docRef);
    setPost(blogDetail.data())
    setLoading(false)
  }

  const refineDate = (dateStr) => {
    const date = moment(dateStr, "ddd MMM DD YYYY");
  
    return date.format("MMMM D, YYYY");
  }

  const formatDescription = (description) => {
    // Replace line breaks with a single <br> tag
    let formattedDescription = description.replace(/\n/g, "<br>");
  
    // Add bold text based on specific patterns (e.g., making 'important' bold)
    formattedDescription = formattedDescription.replace(/\b([A-Z]{2,})\b/g, "<strong>$1</strong>");
  
    return formattedDescription;
  };

  return (
    <HeaderContainer>
      {loading || Object.keys(post).length === 0 ? (
        <Spinner />
      ) : (
        <>
          <div className="wrapper">
            <img
              className="cover"
              src={post.imgUrl}
              alt="banner"
            />
            <div className="flexbox">
              <div className="description">
                <h1
                  dangerouslySetInnerHTML={{ __html: post.title }}
                ></h1>

                <p className="orange">{refineDate(post.createdAt)}.</p>
              </div>
            </div>
          </div>
          <div className="content">
            <div
              className="content__details"
              dangerouslySetInnerHTML={{
                __html: formatDescription(post.description),
              }}
            ></div>
          </div>
          {initialBlogs.length > 1 && (
            <div className="news__letter">
              <Newsletter tagLine="Get notified about latest news and updates. Subscribe with your email address" />
            </div>
          )}
          <Featured
            id={id}
          />
        </>
      )}
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  .wrapper {
    padding: 40px 24px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--mainBlack);
    transition: all 1s ease;
    animation: expand 1s ease-in-out forwards;
    img.cover {
      object-fit: cover;
      width: 100%;
      /* height: 320px; */
      margin-bottom: 30px;
    }

    .flexbox {
      h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #ffffff;
        text-align: center;
      }
      p {
        font-weight: 500;
        color: #f2f2f2;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 16px;
      }
      p.orange {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #f8951d;
      }
      .button__container {
        display: flex;
        justify-content: center;
        margin-top: 32px;
        button {
        }
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 20px 32px;
    
    .content__details {
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      text-align: justify;
      text-justify: inter-word;
      white-space: pre-line;

      p {
        margin-bottom: 24px;
      }
      ul, ol{
        padding-bottom: 10px;
        margin-left: 1rem;
      }
      li {
        /* font-weight: 600; */
        margin-top: 14px;
        /* font-size: 18px; */
      }
      img{
        width: 100%;
        object-fit: contain;
      }
      a{
        color: var(--mainOrange);
      :hover{
        text-decoration: underline;
      }
      }
    }
  }

  .news__letter {
    background-color: var(--mainBlack);
    margin: 80px 0;

    h2 {
      color: white;
    }
    p {
      color: #f2f2f2;
    }
    input {
      background: transparent;
      color: white;
    }
  }

  @media (min-width: 550px) {
    .content__details {
      ul, ol{
        margin-left: 3rem !important;
      }
    }
  }

  @media (max-width: 400px) {
    .content__details {
      word-spacing: 0.8px;
    }
  }

  @media (min-width: 768px) {
    .wrapper {
      width: 100%;

      img.cover {
        width: 856px !important;
        height: 440px;
        margin-bottom: 20px;
      }
      .flexbox {
        display: flex;
        align-items: center;
        justify-content: center;
        .description {
          width: 856px !important;
        }
      }
      h1 {
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        margin-bottom: 24px;
      }
      p {
        margin: 16px 0;
        /* width: 471px; */
      }
      p.orange {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .news__letter {
      margin: 120px 0;
    }
    .content {
      padding: 80px 32px 0;
    }
    .content__details {
      width: 736px;
    }
  }
`;

export default Post;
